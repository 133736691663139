import React from 'react'
import { MAIL_STRING } from '../../../utils/Constant'

// subject: Phocket: NACH Bounced

const LoanDefault = ({ allDetail }) => (
    <div>
        <div>Hello {allDetail != null && allDetail.userName != null ? allDetail.userName : ""},</div>
        <br />
        <div>Your borrowbuddy Loan is under Default for over 7 days now.</div>
        <div>We are now initiating Recovery Proceedings.</div>
        <div>You might be contacted by the Recovery Executive who will further discuss and share the schedule for visit to your Official /Home Address etc.</div>
        <br/><div>Kindly make the payment on immediate priority</div>
        <br />
        {/* <div>Get in touch with us: <a href='tel:+918010700600'>+91-8010700600</a> or <a href='mailto:collections@borrowbuddy.co.in'>collections@borrowbuddy.co.in</a></div> */}
        {/* <br /> */}
        <div>Best Regards,</div>
        <div>Team borrowbuddy</div>
        {/* <br />
        <div>Your loan is still under Default.</div>
        <br />
        <div>We don't want to leverage your social profile by reaching out to all possible contacts (personal references, Facebook friends and mobile contacts).</div>
        <br />
        <div> U might be contacted by field agents.</div>
        <br />
        <div>They will visit your office /Home etc for collection purposes.</div>
        <br />
        <div>Make payment NOW to avoid further action</div>
        <br />
        <div>Best Regards,</div>
        <div>Team Phocket</div>
        <div>{MAIL_STRING.MOBILE_NO}</div> */}
    </div>
)

export default LoanDefault