const PLACEHOLDER = {
    FULL_NAME: "Full Name",
    FIRST_NAME: "First Name",
    MIDDLE_NAME: "Middle Name",
    LAST_NAME: "Last Name",
    DOB: "Date of Birth",
    GENDER: "Gender",
    REFERRAL_CODE: "Referral Code",
    EMAIL_ID: "Email Id",
    MOBILE: "Mobile Number",
    HOW_TO_KNOW: "From where you heard about us",
    ALTERNATE_MOBILE: "Alternate Mobile Number",
    AADHAR_LINKED: "Is Aadhar Mobile Linked",
    ADDRESS: "Address",
    PIN_CODE: "Pincode",
    STATE: "State",
    CITY: "City",
    RESIDANCE_TYPE: "Residence Type",
    DURATION: "Duration",
    HIGHEST_QUALIFICATION: "Highest Qualification",
    OFFICE_EMAIL: "Official Email Id",
    OFFICE_ADDRESS: "Office Address",
    DESIGNATION: "Designation",
    NO_OF_EMPLOYEE: "No. of Employees in your organisation",
    FULL_PART_TIME: "Job Type",
    EMPLOYER_TYPE: "Employment Type",
    ORGANIZATION_NAME: "Organization\'s Name",
    SALARY: "Salary",
    SALARY_DAY: "Salary Day",
    CURRENT_WORK_EXPERIENCE: "Work Experience(in current organization)",
    IS_FIRST_JOB: "Is This Your First Job",
    PREVIOUS_WORK: "Work Experience(in previous organization)",
    TOTAL_EXPERIENCE: "Total Experience",
    SELF_EMPLOYEE_TYPE: "Self Employee Type",
    INDUSTRY_TYPE: "Industry Type",
    OTHER_INDUSTRY_TYPE: "Other Industrial Type",
    EMPLOYEE_ROLE: "Employment Role",
    MONTHLY_INCOME: "Monthly Income",
    BUSINESS_START_DATE: "Business Start Date",
    LOAN_AMOUNT: "Loan Amount (5000 - 100000)",
    LOAN_DAYS: "Tenure (7 - 60)",
    PAN_NO: "PAN Number",
    PAN_NAME: "Name as on Pancard",
    AADHAAR_NO: "Aadhar Number",
    LOAN_REASON: "Loan Reason",
    SELECT_EMI: "Select Your Installment Type",
    COUPON_CODE: "Coupon Code",
    CURRENT_OBLIGATION: "please change to Do you have any current obligation(s) / running loan(s)?",
    CURRENT_OBLIGATION_TEXT: "Please provide details in short. (e.g - Loan amount = Rs. 10,000 and Loan running with XYZ Bank)",
    OTHER_LOAN_REASON: "Other Loan Reason",
    BANK_NAME: "Bank Name",
    BANK_USER_NAME: "Name as per the Bank Account",
    ACCOUNT_NO: "Account Number",
    IFSC_CODE: "IFSC Code",
    BANK_BRANCH: "Branch",
    IF_SALARIED_ACCOUNT: "If Salaried Account",
    SELECT_TRANSACTION_MODE: "Select Mode",
    OUTLET_NAME: 'Outlet Name',
    CONTACT_PERSON_NAME: "Contact Person's Name",
    CONTACT_PERSON_EMAIL: "Contact Person's Email Id",
    CONTACT_NUMBER: "Authorized Person's Contact Number",
    COMPANY_WEBSITE: "Company's Website",
    REGISTERED_ADDRESS: 'Company Registered Address',
    AGREEMENT: 'Signed Agreement Upload',
    USER_ID: 'User ID',
    PASSWORD: 'Password',
    WEEKNAME: 'Select Weekday',
    DOBFORMAT: 'MM/DD/YYYY'

};

const TYPE = {
    FULL_NAME: "FullName",
    FIRST_NAME: "FirstName",
    MIDDLE_NAME: "MiddleName",
    LAST_NAME: "LastName",
    DOB: "DOB",
    GENDER: "Gender",
    REFERRAL_CODE: "referralCode",
    EMAIL_ID: "EmailID",
    MOBILE_NO: "MobileNo",
    AMBASSADOR_ID: "ambassadorId",
    HOW_TO_KNOW: "HowToKnow",
    ALTERNATE_MOBILE: "AlternateMobile",
    APP_LOAN_ID: "LoanId",
    CHEQUE_LIMIT: "chequeLimmit",
    CURRENT_ADDRESS: "CurrentAddress",
    CURRENT_PIN_CODE: "CurrentPin",
    CURRENT_STATE: "CurrentState",
    CURRENT_CITY: "CurrentCity",
    CURRENT_RESIDANCE_TYPE: "CurrentResidanceType",
    CURRENT_DURATION: "CurrentDuration",
    PERMANENT_ADDRESS: "PermanentAddress",
    PERMANENT_PIN_CODE: "PermanentPin",
    PERMANENT_STATE: "PermanentState",
    PERMANENT_CITY: "PermanentCity",
    PERMANENT_RESIDANCE_TYPE: "PermanentResidanceType",
    PERMANENT_DURATION: "PermanentDuration",
    HIGHEST_QUALIFICATION: "HighQualification",
    FULL_PART_TIME: "fullPartTime",
    OFFICE_EMAIL: "officeEmail",
    EMPLOYER_TYPE: "EmployerType",
    ORGANIZATION_NAME: "OrganizationName",
    OFFICE_ADDRESS: "OfficeAddress",
    DESIGNATION: "designation",
    NO_OF_EMPLOYEE: "noOfEmployee",
    SALARY: "HomeSalary",
    SALARY_DAY: "SalaryDay",
    CURRENT_WORK_EXPERIENCE: "WorkExperienceCurrent",
    IS_FIRST_JOB: "IsFirstJob",
    PREVIOUS_WORK: "PreviousWork",
    TOTAL_EXPERIENCE: "WorkExperienceTotal",
    SELF_EMPLOYEE_TYPE: "SelfEmployeeType",
    INDUSTRY_TYPE: "IndustryType",
    OTHER_INDUSTRY_TYPE: "OtherIndustrialType",
    EMPLOYEE_ROLE: "EmployeRole",
    MONTHLY_INCOME: "MonthlyIncome",
    BUSINESS_START_DATE: "businessStartDate",
    ACTUCAL_RECEIVED_AMOUNT: 'actualRecievedAmount',
    LOAN_AMOUNT: "loanAmount",
    FINAL_PAYBLE_AMOUNT: "finalPaybleAmount",
    INTEREST_AMOUNT: "interestAmount",
    PAID_AMOUNT: "paidAmount",
    PENALTY_AMOUNT: "penaltyAmount",
    NACH_BOUNCE_AMOUNT: "nachBounceAmount",
    CHEQUE_BOUNCE_AMOUNT: "chequeBounceAmount",
    PROCESSING_FEES_AMOUNT: "processingFeesAmount",
    LOAN_DAYS: "loanDays",
    PAN_NO: "PanNo",
    PAN_NAME: "PanName",
    AADHAAR_NO: "aadharNo",
    SELECT_EMI: "SelectEmi",
    LOAN_REASON: "loanReason",
    COUPON_CODE: "couponCode",
    CURRENT_OBLIGATION: "currentObligation",
    CURRENT_OBLIGATION_TEXT: "currentObligationText",
    OTHER_LOAN_REASON: "OtherLoanReason",
    AADHAR_LINKED: "isAadharMobileLinked",
    BANK_NAME: "BankName",
    BANK_USER_NAME: "NameInBankAccount",
    ACCOUNT_NO: "AccountNo",
    IFSC_CODE: "IFSCCode",
    BANK_BRANCH: "BankBranch",
    IF_SALARIED_ACCOUNT: "IfSalriedAccount",
    PAN_CARD: "panCard",
    BANK_STATMENT: "bankStatement",
    ADHAR_CARD: "adharCard",
    ITR_STATEMENT: "itrStatement",
    PHOTO: "photograph",
    SALARY_PROOF: "salaryProof",
    CIBIL_REPORT: "cibilReport",
    ADDRESS: "addressProof",
    ID_PROOF: 'idProof',
    MISCELLANEOUS: 'miscellaneous',
    OFFICE_ID_PROOF: 'officeIdProof',
    CURRENT_ADD_PROOF: 'currentAddressProof',
    NO_LOAN_STATUS: "no loan",
    ON_HOLD: "on hold",
    EMP_ID: "employeeId",
    EMP_PASS: "employeePassword",
    EMP_NAME: "employeeName",
    EMP_ROLE: "employeeRole",
    EMP_PERMISSION: "empPermission",
    EMP_DEPARTMENT: "empDepartment",
    NBFC_NAME: "nbfcName",
    OLD_PASS: "oldPassword",
    NEW_PASS: "newPassword",
    CONFIRM_PASS: "confirmPassword",
    LOAN_APPROVE_DATE: "ApproveDate",
    LOAN_PAID_DATE: "PaidDate",
    LOAN_PAYBACK_DATE: "PaybackDate",
    FIRST_REPAYMENT_DATE: "firstRepaymentDate",
    SECOND_REPAYMENT_DATE: "secondRepaymentDate",
    THIRD_REPAYMENT_DATE: "thirdRepaymentDate",
    REPAYMENT_DATE: "EMIRepaymentDates",
    DAY_WISE: 'Daywise',
    MONTH_WISE: 'Monthwise',
    BETWEEN_DATE_WISE: 'BetweenDatewise',
    OVER_ALL: 'Over All',
    OUTLET_NAME: 'OutletName',
    CONTACT_PERSON_NAME: "ContactPersonName",
    CONTACT_PERSON_EMAIL: "ContactPersonEmail",
    CONTACT_NUMBER: "ContactNumber",
    COMPANY_WEBSITE: "CompanyWebsite",
    REGISTERED_ADDRESS: 'RegisteredAddress',
    AGREEMENT: 'SignedAgreementUpload',
    USER_ID: 'UserID',
    PASSWORD: 'Password',
    CHEQUE: 'cheque',
    LOCATION: 'office/Home',
    OFFICE_ID: 'officeId'
};

const EMPLOYER_TYPE = {
    SALARY: "Salaried",
    SELF_EMPLOYED: "Self Employed"
}

const STRING = {
    MALE: "Male",
    FEMALE: "Female",
    OTHERS: "Others",
    OWN: "Own",
    RENTED: "Rented",
    HOSTEL: "PG/Hostel",
    GRADUATE: "Graduate",
    POST_GRADUATE: "Post Graduate",
    PART_TIME: "Part Time",
    FULL_TIME: "Full Time",
    EXP_1: "0 - 1 year",
    EXP_2: "1 - 3 years",
    EXP_3: "3 - 5 years",
    EXP_4: "More than 5 years",
    YES: "yes",
    NO: "no",
    BUSINESS: "Business",
    PROFESSIONAL: "Professional",
    MANUFACTURING: "Manufacturing/Engineering",
    TRADING: "Trading",
    SERVICES: "Services",
    EDUCATION: "Education",
    SHOPPING: "Shopping",
    TRAVEL: "Travel",
    MEDICAL: "Medical Emergency",
    CARD: "Card Payment",
    EMI: "EMIs",
    PENDING_FOLLOW_UP: "Pending follow-up",
    CONFIRMATION_PENDING: "Counter offer made- confirmation pending",
    PENDING_STATUS: "101",
    NEW_LEAD: "102",
    COMMUNICATED: "103",
    APPROVED_STATUS: "105",
    DISBURSED: "107",
    RECEIVED: "108",
    PAID: "109",
    REJECT_STATUS: "110",
    CANCEL_BY_USER: "111",
    SELECT_ALL: "112",
    PAYMENT_OVERDUE: "114",
    PAYMENT_ONGOING: "119",
    CHEQUE_BOUNCE: '115',
    PERMANENT_REJECT: "116",
    LOAN_UNDER_PROCESS: "117",
    SOFT_APPROVAL: "120",
    COMPLETE_APPLICATION: "150",
    CHEQUE: "Cheque",
    NET_BANKING: "Net Banking",
    HALFSALARYMSG: "As per our policy we only provide loan upto 100% of the take home salary for the period of 7-60 Days.",
    FIREBASEMSG: "firebase-messaging-msg-data",
    COMMON_INFO: "Common Information",
    CREDIT_LIMIT: "Credit Limit",
    DEBIT_LIMIT: "Debit Limit",
    INCOME_LIMIT: "Income Limit",
    GOOGLE: "Google",
    FB: "Facebook",
    OUTDOOR_EVENTS: "Outdoor Events",
    CORPORATE_EVENTS: "Corporate Events",
    PEERS: "Peers",
    BOUNCE: "Bounce",
    CLEARED: "Cleared",
    NULL_EMI: "0",
    FIRST_EMI: "1",
    SECOND_EMI: "2",
    THIRD_EMI: "3",
    FOURTH_EMI: "4",
    SIXTH_EMI: "6",
    TWELFTH: "12",
    EMI_MSG: "Months EMI Convert After Loan Approved. Check EMI After Loan Approval into Your EMI Section",
    DISCRIPTION_APPLICATION_ANALYSIS: "Data of all the unique applicants who have filled or have attempted to fill the application.",
    DISCRIPTION_APPLICATION_ANALYSIS_GENDER: "\n\n The graph shows unique Gender-wise data. Here,\n\n Overall Count refers to Total count of each unique gender.\n\n Complete Application refers to the total number of applicants who have applied for loan.\n\n Incomplete Application Count refers to number of applicants who have attempted to fill the application but have not applied for loan yet.",
    DISCRIPTION_APPLICATION_ANALYSIS_DEVICE: "\n\n The graph shows unique device data that is used for filling the application. Here,\n\n Overall Count refers to Total count of each unique device.\n\n Complete Application refers to the total number of applicants who have applied for loan.\n\n Incomplete Application Count refers to number of applicants who have attempted to fill the application but have not applied for loan yet.",
    DISCRIPTION_APPLICATION_ANALYSIS_LOGIN_MODE: "\n\n The graph displays data of unique Mode of Login that is used for Logging in to proceed for application process. Here,\n\n Overall Count refers to Total count of each Login Mode.\n\n Complete Application refers to the total number of applicants who have applied for loan using each mode of login.\n\n Incomplete Application Count refers to number of applicants who have attempted to fill the application but have not applied for loan yet.",
    DISCRIPTION_APPLICATION_ANALYSIS_SOURCE: "\n\n The graph displays data of unique platform from where they have known about BorrowBuddy. Here,\n\n Overall Count refers to Total count of each Platform.\n\n Complete Application refers to the total number of applicants who have applied for loan using each mode of login.\n\n Incomplete Application Count refers to number of applicants who have attempted to fill the application but have not applied for loan yet.",
    DESCRIPTION_FORM_STAGE: "The graph displays data of unique Form Stages of unique applicant. Here, form stage is the name of that form where the applicant has left the application filling process.\n The order of the form stage is - Basic, Address, Occupation, Bank, Document, Loan, Thanks. \n Basic Form takes the basic detail of user. \n Address Form contains the residential details of the applicant. The address form stage has an integrated graph which shows how many applicant has applied from servicable or non-servicable location.\n Occupation form takes data of users Employment details. It has an integrated graph which shows how many applicant are Salaried or Self Employed. \n The Bank Form takes the data of Account details. \n The Document Form takes the documents which are required for Loan Process. \n Loan form takes loan details of applicants. \n Thanks form status states that the applicant has completed the form. On Hold status are those who have applied for 1 year product.",
}

const MAIL = {
    FINANCE: "finance@borrowbuddy.co.in",
    CONFIG: "config@borrowbuddy.co.in"
}

const FORM_STATUS = {
    BASIC: "Basic",
    RESIDANCE: "Address",
    OCCUPATIONAL: "Occupation",
    BANK: "Bank",
    LOAN: "Loan",
    DOCUMENTS: "Documents",
    THANKS: "Thanks",
    UPDATE: "Update",
}

const EMPLOYEE_ROLE = {
    ADMIN: "Admin",
    MANAGER: "Manager",
    USER: "User",
    SERVICE: "Service"
}

const ROLE = {
    LOAN_APPROVAL_LEADS: "201",
    INCOMING_PAYMENT: "202",
    PENDING_PAYMENT: "203",
    EDIT: "205",
    DOCS: "206",
    MESSAGE: "207",
    ACTION: "208",
    COMMUNICATION: "209",
    TRANSACTION: "210",
    LOCATION: "211",
    VIEW: "212",
    ROLE_ASSIGN: "213",
    SENDER_MAIL: "214",
    PROMOTION: "215",
    BULK_COMMUNICATION: "216",
    BULK_STATUS_CHANGE: "217",
    AMBASSADOR: "218",
    LOYALITY: "219",
    NACH_CHANGE: "220",
    ADD_LOAN_ID: "221",
    IVR: "222",
    ADD_APPLICATION_IN_AMBASSADOR: "223",
    LEAD_SELECTION: "224",
    MARK_AS_IMPORTANT: "225",
    STATUS_CHANGE_DROPDOWN: "226",
    PROFILE_ANALYSIS: "227",
    APPLICATION_STATUS: "228",
    DASHBOARD_REPORT: "229",
    COLLECTION_SECTION: "230",
    FIELD_OPERATION: "231",
    UTILITY: "232",
    LEAD_MANAGEMENT: "233",
    USER_FEEDBACK: "234",
    STATUS_ACTIVITY: "235",
    USER_EMI: "236",
    TRACK_CAMPAIGN: "237",
    CONTACT_APP: "238",
    // WEB_CONTENT: "239",
    CRIF_DATA: "239",
    PAN_VERIFY: "240",
    INCOME_COLLECTED: "241",
    DASHBOARD_REPORT_NEW: "242",
    ACTION_AND_DISBURSE: '243',
    BULK_OPERATION: '244',
    FINBIT_DATA: '245',
    ////new roles///
    Gift_Card_Leads: '247',
    Birds_Eye_View: '248',
    Cibil_Scoring: '249',
    Bank_Detail_Analysis: '250',
    Similar_Profile: '251',
    Mark_PFD: '252',
    Mark_RFD: '253',
    Mark_Complete: '254',
    Mark_InComplete: '255',
    Tentative_Approval: '256',
    Dashboard: '257',
    Incoming_Payment_Report: '258',
    Generate_Buddy_Loan_Report: '259',
    Check_FlipKart_Balance: '260',
    Generate_Report_Logs: '261',
    // Bulk_Communication: '262',
    Initiate_Bulk_Payment: '264',
    Bulk_Nach_Bounce: '265',
    Approved_To_Disbursed_Disbursed_By_ICICI: '266',
    Bulk_Paid: '267',
    Bulk_Nach_Status_Update: '268',
    Nach_Registration: '269',
    Disbursed_Leads: '270',
    Incoming_Leads: '271',
    Asset_Quality: '272',
    Loan_Emi_Calculator: '273',
    Update_Web_Content: '274',
    Add_Priority_Company: '275',
    Add_Nbfc: '276',
    Add_Pincode: '277',
    Update_User_Bank_Details: '278',
    Update_Cibil_Info: '279',
    Manual_Lead_Assignment: '280',
    LM_Assignment_Rule: '281',
    Collection_Lead_Rule: '282',
    LM_Credit_Dashboard: '283',
    LM_Customer_Service_Dashboard: '285',
    LM_Legal_Dashboard: '286',
    Bada_Business_Leads: '287',
    Tea_Garden: '288',
    Card_Product: '289',
    Vreedhi_Leads: '290',
    Lead_Count: '291',
    UPLOAD_STAMP_DUTY: '292',
    TE_Profile_View: '293',
    TE_Edit_Profile: '294',
    TE_Analysis: '295',
    TE_Loan_Report: '296',
    TE_EMI: '297',
    TE_Action: '298',
    TE_Gift_Card_Leads: '299',
    TE_Upload_Utility: '300',
    TE_Ambassador_View: '301',
    TE_Ambassador_Add: '302',
    EDIT_REFERENCE: '303',
    NBFC_CONFIGURATION: '304',
    API_TRACKER: '305',
    CREDIT_ASSESSMENT: '306',
    GENERATE_REPORT_ROLE: '307',
    MARKETING_TOOL: '308',
    AADHAAR_MASKED: '309',
    API_TRACKER_LIST: '310',
    API_TRACKER_CONFIG: '311',
    API_TRACKER_CREDIT_ANALYSIS: '312',
    SEND_NOC: '313',
    OPERATIONS_JOURNEY: '314',
    LOS: '315',
    LMS: '316',
    BUSINESS: '317',
    FINANCE: '318',
    MARKETING_MAIN: '319',
    ALL_POLICY: '320',
    RAZORPAY_REPORT: '321',
    ACTION_PAID: '322',
    ANALYSIS_DASHBOARD: '323',
    ANALYSIS_KYC: '324',
    ANALYSIS_CREDIT_POLICY_CHECKLIST: '325',
    CRIF_RE_GENERATE: '326',
    FINBIT_RE_GENERATE: '327',
    CHECK_VIA_FINBIT: '328',
    DEACTIVATE_NACH: '329',
    MOBILE_NO_EDIT: '330',
    STAMP_DUTY_TRACKER: '332',
    EMAIL_SMS_CONFIG: '333',
    EXECUTOR_ROLE: '334',
    ALL_PRODUCTS:'335',
    NPA_DASHBOARD:'336',



}

const MERCHANT_CATEGORY_NAME = {
    RESTAURANT: "Restaurant",
    HEALTH_AND_BEAUTY: "Health & Beauty",
    TRAVEL: "Travel",
    // PROMOTIONAL_DATA: "204",
    AUTOMOBILES: "Automobiles",
    ACTIVITIES_AND_HOBBIES: "Activities & Hobbies",
    ONLINE_OFLINE_SHOPING: "Online & Offline Shopping",

}

const ACCOUNTDETAIL = {
    COMPANY_NAME: 'Name : Superline FinInvest Private Limited',
    BANK_NAME: 'Bank : ICICI Bank',
    BRANCH_NAME: 'Branch : Rohini Branch',
    ACCOUNT_NUMBER: 'A/C : 629805028551',
    IFSC_CODE: 'ifsc code : ICIC0006298'
}

const MAIL_STRING = {
    WEB_URL_NACH: "https://drive.google.com/open?id=1BicYQIO5j7GKmLOqtsNIMLylFnfbKpNU",
    WEB_URL: "https://borrowbuddy.co.in/uploadDocuments/",
    MOBILE_NO: "7428493367",
    EMAIL_ID: "recovery@borrowbuddy.co.in",
    FINANCE_MAIL_ID: "finance@borrowbuddy.co.in",
    KYC_URL: 'https://admin.borrowbuddy.co.in/kyc/'
}

const MSG_STRING = {
    EXECUTIVE_CONTACT: "BorrowBuddy LOAN: We tried reaching you but there was no response from your end.\n\nKindly revert back for further processing of your loan application.",
    DOC_MSG: "BorrowBuddy LOAN: We have noticed that your loan application process is not complete yet and is missing a few documents.\nCheck your mail for list of missing docs.",
    NACH_BOUNCE: "BorrowBuddy LOAN: Your NACH has bounced due to insufficient balance.\n\nGet in touch with us to avoid hefty penalties: " + MAIL_STRING.MOBILE_NO + " or recovery@borrowbuddy.co.in",
    CHEQUE_BOUNCE: "BorrowBuddy LOAN: Your cheque has bounced due to insufficient balance.\n\nGet in touch with us to avoid hefty penalties: " + MAIL_STRING.MOBILE_NO + " or recovery@borrowbuddy.co.in",
    CHEQUE_DEPOSITE_ALERT: "Your BorrowBuddy loan is overdue and we were unable to reach you.\n\nContact: " + MAIL_STRING.MOBILE_NO + " or recovery@borrowbuddy.co.in. Else we will deposit cheque tomorrow.",
    LEGAL_NOTICE: "Your BorrowBuddy loan is overdue and we were unable to reach you.\n\nWe are proceeding for legal action. \n\nContact: " + MAIL_STRING.MOBILE_NO + " or recovery@borrowbuddy.co.in to stop it.",
    RAZ_MSG: "Your bank is not live with net-banking e-mandate facility. You will have to courier signed loan agreement, security cheque and NACH mandate. Check your mail.",
    GOOGL_PLAY_RATINGS: "WIN WITH BorrowBuddy: Share your valuable reviews and ratings on Google Play store and Get prizes worth INR 2,500. Here is the Link: app.borrowbuddy.co.in/cash",
    LOAN_UNDER_DEAFULT: "Your loan is still under Default.We don't want to leverage your social profile by reaching out to all possible contacts (personal references, Facebook friends and mobile contacts).U might be contacted by field agents.They will visit your office /Home etc for collection purposes.Make payment NOW to avoid further action.\n\nContact: " + MAIL_STRING.MOBILE_NO + " or recovery@borrowbuddy.co.in",
    GET_A_PHOCKET_PL: "Hi Customer! Need urgent cash? Get a BorrowBuddy PL credited in your a/c in minutes | Low interest rates | Apply - https://bit.ly/2NPpBP4"
}

const STATE_COLOR = {
    RAJASTHAN: '#fbd6dc',
    WEST_BENGAL: '#bfe1ef',
    MAHARASHTRA: '#daf7a6',
    KARNATAKA: '#AAC0AA',
    LOAN_ADDA: '#f9f4ae'
}

const emandateEsignMsg = {
    HopeLine: "Hope you are doing good!",
    nachRejected: "Your Physical NACH registration got rejected.",
    mendateRejected: "Your e - Mandate registration got rejected.",
    continueNach: "In order to make sure you continue to avail our service month on month without any hassles BorrowBuddy brings to you e-mandate registration via net banking.",
    normalNach: "In order to ensure hassle-free repayments BorrowBuddy brings to you e-mandate registration via net banking that will automate your EMI payments.",
    salaryRecord: "We will register e-mandate for 100% of your salary (as per our records) so that we can keep increasing your limit to reward you for your excellent payment track record.",
    paperlessEsign: "In order to ensure paperless procedure BorrowBuddy brings to you e-Sign feature that will help you e-Sign your Loan Agreement via Aadhaar OTP.",
    esignEmandate: "In order to ensure paperless and hassle-free procedure BorrowBuddy brings to you e-Sign and e-Mandate feature where e-Sign feature will help you e-sign your Loan Agreement via Aadhaar OTP and e-mandate registration via net banking will automate your EMI repayments.",
    cooperation: "Thank you for your cooperation.",
    steps: "Just a final step is left before you get your approved loan amount.",
    clickNach: "Click here to proceed for eNACH: ",
    clickSign: "Click here to proceed for eSign: ",
    footer: "Warm Regards,\nTeam BorrowBuddy",
    razorpay: "Your bank is not live with net-banking e-mandate facility. You will have to courier signed loan agreement, security cheque and NACH mandate. Kindly acknowledge mail sent.",
    billDeskNach: "In order to ensure hassle-free repayments BorrowBuddy brings to you e-mandate registration via net banking that will automate your EMI payments.",


}
const WEEKNAME = {
    MONDAY: "Monday",
    TUESDAY: "Tuesday",
    WEDNESDAY: "Wednesday",
    THURSDAY: "Thursday",
    FRIDAY: "Friday",
    SATURDAY: "Saturday",
    SUNDAY: "Sunday"
}


export {
    MERCHANT_CATEGORY_NAME,
    PLACEHOLDER,
    TYPE,
    EMPLOYER_TYPE,
    STRING,
    FORM_STATUS,
    EMPLOYEE_ROLE,
    MAIL_STRING,
    MAIL,
    ROLE,
    ACCOUNTDETAIL,
    MSG_STRING,
    STATE_COLOR,
    emandateEsignMsg,
    WEEKNAME
};
