import React from 'react'
// import PhocketLogo from '../../../images/phocket-logo_white 1.png'
import borrowbuddyLogo from '../../../images/Borrowbuddy-logo2.png';
import RingTone from '../../../sound/apple_sms.mp3'
import { ROLE } from '../../../utils/Constant'
import LosImage from '../../../images/LOS.png'
import LMSImage from '../../../images/business.png'
import BusinessImage from '../../../images/LMS.png'
import FinanceImage from '../../../images/finance.png'


const CRMHeader = ({ pageViewState, menuType, mainMenuHandler, menustatesmall, signOutHandler, headerNotificationHandler, notificationCount,
    notificationDocUpCount, menuShowHandler, menuState, notificationSoundState, admin, menuhandler,
    pageViewHandler, handleSongFinishedPlaying, playingState, sessionRemainingTime }) => (
    <div className="container-fluid Phocket-crm-header ">
        <div className='row'>
            <div className="col-sm-2 col-xs-6 phocket_icon">
                <div >
                    {menuState == false && pageViewState !== 'landingPage' ?
                        <span className="fa fa-bars  font-25px v-align" style={{ cursor: 'pointer', color: '#fff' }} onClick={(e) => menuShowHandler('show')} />
                        : ""}
                    {menuState == true && pageViewState !== 'landingPage' ?
                        <span className="fa fa-times  font-25px v-align" style={{ cursor: 'pointer', color: '#fff' }} onClick={(e) => menuShowHandler('hide')} />
                        : ""}
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <img src={borrowbuddyLogo} />
                </div>
            </div>
            <div className='col-sm-6 colxs-12'>
                <div className='divide-width'>
                    {admin.rolelist != undefined ? admin.rolelist.indexOf(ROLE.LOS) >= 0 ?
                        <li className='hidden-xs '>
                            <div className={menuType === 'LOS' ? ' text-center main-tab-active' : ' text-center main-tab '} onClick={(e) => mainMenuHandler(e,'LOS')}><div className=''> <img src={LosImage} style={{ width: '22px' }} />&nbsp;&nbsp;LOS</div></div>
                        </li>
                        : "" : ""}
                    {admin.rolelist != undefined ? admin.rolelist.indexOf(ROLE.LMS) >= 0 ?

                        <li className='hidden-xs '>
                            <div className={menuType === 'LMS' ? ' text-center main-tab-active' : ' text-center main-tab '} onClick={(e) => mainMenuHandler(e,'LMS')}><div className=''> <img src={LMSImage} style={{ width: '22px' }} />&nbsp;&nbsp;LMS</div></div>
                        </li>
                        : "" : ""}
                    {admin.rolelist != undefined ? admin.rolelist.indexOf(ROLE.BUSINESS) >= 0 ?

                        <li className='hidden-xs '>
                            <div className={menuType === 'BUSINESS' ? ' text-center main-tab-active' : ' text-center main-tab '} onClick={(e) => mainMenuHandler(e,'BUSINESS')}><div className=''> <img src={BusinessImage} style={{ width: '22px' }} />&nbsp;&nbsp;BUSINESS</div></div>
                        </li>
                        : "" : ""}
                    {admin.rolelist != undefined ? admin.rolelist.indexOf(ROLE.FINANCE) >= 0 ?
                        <li className='hidden-xs '>
                            <div className={menuType === 'FINANCE' ? ' text-center main-tab-active' : ' text-center main-tab '} onClick={(e) => mainMenuHandler(e,'FINANCE')}><div className=''> <img src={FinanceImage} style={{ width: '22px' }} />&nbsp;&nbsp;FINANCE</div></div>
                        </li>
                        : "" : ""}
                </div>
            </div>
            {/* <div className='col-sm-2 col-xs-12 hidden-xs'></div> */}
            <div className="col-sm-4 col-xs-6 crm-personal-profile">
                {/* {admin.rolelist != undefined && admin.rolelist.indexOf(ROLE.LEAD_SELECTION) >= 0 ? */}
                <span className="hidden-xs fa fa-lg fa-home crm-header-not-icon whats-app-nbfc pointer" style={{ color: menuType === 'SUPERADMIN' ? '#006fbc' : '#fff' }} onClick={(e) => pageViewHandler(e, 'landingPage')} >
                </span>
                {/* // : null} */}
                &nbsp;&nbsp;
                {admin.rolelist != undefined && admin.rolelist.indexOf(ROLE.LEAD_SELECTION) >= 0 ?
                    <span style={{ color: '#fff' }} className="hidden-xs fa fa-lg fa-whatsapp crm-header-not-icon whats-app-nbfc pointer" onClick={e => headerNotificationHandler('docs')} >
                        <a className="blink">{notificationDocUpCount > 0 ?
                            <div className="not-count-circle ">{notificationDocUpCount}</div>
                            : ""}</a>
                    </span>
                    : null}
                &nbsp;&nbsp;
                {admin.rolelist != undefined && admin.rolelist.indexOf(ROLE.LEAD_SELECTION) >= 0 ?
                    <span style={{ color: '#fff' }} className="hidden-xs fa fa-lg fa-bell-o crm-header-not-icon bell-nbfc pointer" onClick={e => headerNotificationHandler('notDocs')} >
                        <a className="blink">   {notificationCount > 0 ?
                            <div className="not-count-circle-bell">{notificationCount}</div>
                            : ""}</a>
                    </span>
                    : null}
                &nbsp;&nbsp;&nbsp;&nbsp;
                <span className="dropdown">
                    <a style={{ color: '#fff' }} className="crm-name-img  pointer" onClick={() => menuhandler()}>
                        <i className={menustatesmall ? 'fa fa-chevron-up' : 'fa fa-chevron-down'} style={{ fontSize: '13px', marginBottom: '10px', color: '#fff' }}></i>&nbsp;
                        <span className="admin-name" style={{ color: '#fff' }} >
                            {admin != null && admin.name != null ? admin.name.split(" ")[0] : "NoUser"}</span>
                    </a>
                    &nbsp;&nbsp;

                    <ul className="dropdown-menu pointer" id='dropdownsmall'>
                        <li><a onClick={(e) => pageViewHandler(e, 'changePassword')}>Change Password</a></li>
                        {admin.rolelist != undefined ? admin.rolelist.indexOf(ROLE.ROLE_ASSIGN) >= 0 ?
                            <li><a onClick={(e) => pageViewHandler(e, 'BulkAssign')}>Bulk Assign</a></li>
                            : "" : ""}
                        <li><a onClick={(e) => pageViewHandler(e, 'SoundSetting')}>Sound Setting</a></li>
                        <li><a onClick={signOutHandler}>Sign Out</a></li>
                    </ul>
                </span>
            </div>
        </div >
        {playingState == true && notificationSoundState == true ?
            <audio autoPlay onEnded={handleSongFinishedPlaying}>
                <source src={RingTone} type="audio/mpeg" />
            </audio>
            : ""}
    </div >







)

export default CRMHeader;