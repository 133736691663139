import React from 'react'
import { BASE_URL } from '../../../../utils/api-factory'
import { ROLE } from '../../../../utils/Constant'
import Nodata from '../../../../images/nodata.png'
import VerifyImage from '../../../../images/verified.png'
import NotVerified from '../../../../images/cross.png'
import moment from 'moment'
import { WEB_URL } from '../../../../utils/api-factory'
let URLTOSEND = BASE_URL.includes('test') ? 'https://test.borrowbuddy.co.in/' : 'https://borrowbuddy.co.in/'
const userBasic = ({karzaData, maskAadhar, changeVeriFyStatus, bankData, deactivateNach, userReference, markincompleteapplication, appVersion, vreedhiData, userDetail, userLinkHandler, linkState, allDetail, panVerifiedstate, userScore, verifyMail, admin, randomValues, verifyHandler, saveVerify, karzaPopup, paninfo, aadharInfo }) => (
    <div className="container-fluid ">
        <br />
        {/* {userDetail.typeOfProduct == 'Loan'?
        <div className="row">
            <div className="col-xs-12">
                <h4 className="margin-bottom-25px"><strong className="profile-h">Credit Score</strong></h4>
            </div>
            {userScore != null && userScore != '' && userScore != undefined ?
                <div className="col-xs-12 top-bottom-10px">
                    <div className="overall-score">
                        <b>{userScore.score}</b>
                    </div>
                </div>
                : ""}
        </div> 
        :""}
        { userDetail!=null && userDetail!=undefined?userDetail.typeOfProduct == 'Loan'?
         <div className="row">
            {userScore != '' ? userScore.description != null ?
                Object.keys(userScore.description).map((mainKeys, i) => {
                    return (
                        <div key={i} className="col-sm-4 col-xs-12">
                            <p className="text-capitalize">
                                <b>{mainKeys.replace(/_/g, ' ') + ' - '}</b>
                                {userScore.description[mainKeys] != null && userScore.description[mainKeys] != '' ?
                                    isNaN(userScore.description[mainKeys]) ?
                                        userScore.description[mainKeys].replace(/_/g, ' ')
                                        : userScore.description[mainKeys]
                                    : null}</p>
                        </div>
                    )
                })
                : <div className="col-xs-12"><h3 className="text-center blue-text">No Data Found</h3></div>
                : <div className="col-xs-12 dot-loader min-height-135px">
                    <span></span>
                    <span></span>
                    <span></span>
                </div>}
        </div> 
        :"":""} */}
        <div className='row'>
            <div className='col-sm-4 col-xs-12'>
                {admin != null && admin.rolelist != undefined && admin.rolelist.indexOf(ROLE.COMMUNICATION) >= 0 && linkState == false ?
                    <button className='profile-save-btn' onClick={() => userLinkHandler('yes')}>Click Here For User Links</button>
                    : ""}
            </div>

            <div className='col-sm-4 col-xs-12' style={{ textAlign: '-webkit-center' }}>
                {deactivateNach ?
                    <div className='highlight-new'>User Requested to Deactivate NACH</div>
                    : ""}
            </div>

            {/* <div className='col-sm-4 col-xs-12' style={{ textAlign: 'right' }}>
                <button className='profile-save-btn' onClick={() => markincompleteapplication()}>Mark Application Incomplete</button>
            </div> */}

        </div>


        {admin != null && admin.rolelist != undefined && admin.rolelist.indexOf(ROLE.COMMUNICATION) >= 0 && linkState == true ?
            <div className='row animated slideInLeft'>
                <button style={{ marginLeft: '15px' }} className='profile-save-btn' onClick={() => userLinkHandler('no')}>Close Links</button>

                <div className="col-xs-12">
                    <br />
                    <p><b>Upload Document Link :&nbsp;</b><a target='_blank' href={allDetail != null ? allDetail.userId != null ? URLTOSEND + "uploadDocuments/" + allDetail.userId : "" : ""}>{allDetail != null ? allDetail.userId != null ? URLTOSEND + "uploadDocuments/" + allDetail.userId : "" : ""}</a></p>
                </div>
                <div className="col-xs-12">
                    <p><b>Feedback Link :&nbsp;</b><a target='_blank' href={allDetail != null ? allDetail.userId != null ? URLTOSEND + "feedback/" + allDetail.userId : "" : ""}>{allDetail != null ? allDetail.userId != null ? URLTOSEND + "feedback/" + allDetail.userId : "" : ""}</a></p>
                </div>
                <div className="col-xs-12">
                    {userDetail.loanId != '' && userDetail.loanId != null ?
                        <p><b>E-Mandate with Rajorpay Link :&nbsp;</b><a target='_blank' href={userDetail != null ? userDetail.loanId != null ? URLTOSEND + "enach/" + userDetail.loanId : "" : ""}>{userDetail != null ? userDetail.loanId != null ? URLTOSEND + "enach/" + userDetail.loanId : "" : ""}</a></p>
                        : userDetail.giftCardId != '' && userDetail.giftCardId != null ? <p><b>E-Mandate with Rajorpay Link :&nbsp;</b><a target='_blank' href={userDetail != null ? userDetail.giftCardId != null ? URLTOSEND + "enach/" + userDetail.giftCardId : "" : ""}>{userDetail != null ? userDetail.giftCardId != null ? URLTOSEND + "enach/" + userDetail.giftCardId : "" : ""}</a></p> : ""}
                </div>
                <div className="col-xs-12">
                    {userDetail.loanId != '' && userDetail.loanId != null && userDetail.typeOfProduct == 'Loan' ?
                        <p><b>E-Mandate with TechProcess Link :&nbsp;</b><a target='_blank' href={userDetail != null ? userDetail.loanId != null ? URLTOSEND + "eNach/" + userDetail.loanId : "" : ""}>{userDetail != null ? userDetail.loanId != null ? URLTOSEND + "eNach/" + userDetail.loanId : "" : ""}</a></p> : ""}
                    {/* //    :userDetail.giftCardId!= '' &&userDetail.giftCardId!= null? <p><b>E-Mandate with TechProcess Link :&nbsp;</b>{userDetail != null ? userDetail.giftCardId != null ? URLTOSEND + "eNach/" + userDetail.giftCardId : "" : ""}</p>:""} */}
                </div>
                <div className="col-xs-12">
                    {userDetail.loanId != '' && userDetail.loanId != null ?
                        <p><b>E-Sign Link :&nbsp;</b><a target='_blank' href={userDetail != null ? userDetail.loanId != null ? URLTOSEND + "esign/" + userDetail.loanId : "" : ""}>{userDetail != null ? userDetail.loanId != null ? URLTOSEND + "esign/" + userDetail.loanId : "" : ""}</a></p>
                        : userDetail.giftCardId != '' && userDetail.giftCardId != null ? <p><b>E-Sign Link :&nbsp;</b><a target='_blank' href={userDetail != null ? userDetail.giftCardId != null ? URLTOSEND + "esign/" + userDetail.giftCardId : "" : ""}>{userDetail != null ? userDetail.giftCardId != null ? URLTOSEND + "esign/" + userDetail.giftCardId : "" : ""}</a></p> : ""}
                </div>
                <div className="col-xs-12">
                    {userDetail.loanId != '' && userDetail.loanId != null && userDetail.typeOfProduct == 'Loan' ?
                        <p><b>E-Mandate with Bill-Desk Link :&nbsp;</b><a target='_blank' href={userDetail != null ? userDetail.loanId != null ? 'https://payment.borrowbuddy.co.in/' + "emandate/" + userDetail.loanId : "" : ""}>{userDetail != null ? userDetail.loanId != null ? 'https://payment.borrowbuddy.co.in/' + "emandate/" + userDetail.loanId : "" : ""}</a></p> : ""}
                    {/* //    :userDetail.giftCardId!= '' &&userDetail.giftCardId!= null? <p><b>E-Mandate with TechProcess Link :&nbsp;</b>{userDetail != null ? userDetail.giftCardId != null ? URLTOSEND + "eNach/" + userDetail.giftCardId : "" : ""}</p>:""} */}
                </div>
                <div className="col-xs-12">
                    {userDetail.loanId != '' && userDetail.loanId != null && userDetail.typeOfProduct == 'Loan' ?
                        <p><b>E-Mandate with Signdesk Link :&nbsp;</b><a target='_blank' href={userDetail != null ? userDetail.loanId != null ? 'https://payment.borrowbuddy.co.in/' + "smandate/" + userDetail.loanId : "" : ""}>{userDetail != null ? userDetail.loanId != null ? 'https://payment.borrowbuddy.co.in/' + "smandate/" + userDetail.loanId : "" : ""}</a></p> : ""}
                    {/* //    :userDetail.giftCardId!= '' &&userDetail.giftCardId!= null? <p><b>E-Mandate with TechProcess Link :&nbsp;</b>{userDetail != null ? userDetail.giftCardId != null ? URLTOSEND + "eNach/" + userDetail.giftCardId : "" : ""}</p>:""} */}
                </div>
                <div className="col-xs-12">
                    <p><b>KYC Link :&nbsp;</b><a target='_blank' href={allDetail != null ? allDetail.userId != null ? 'https://payment.borrowbuddy.co.in/' + "kyc/" + allDetail.userId : "" : ""}>{allDetail != null ? allDetail.userId != null ? 'https://payment.borrowbuddy.co.in/' + "kyc/" + allDetail.userId : "" : ""}</a></p>
                </div>
                <div className="col-xs-12">
                    <p><b>KFS Link :&nbsp;</b><a target='_blank' href={allDetail != null ? allDetail.userId != null ? 'https://payment.phocket.co.in/' + "kfs/" + allDetail.loanId : "" : ""}>{allDetail != null ? allDetail.loanId != null ? 'https://payment.phocket.co.in/' + "kfs/" + allDetail.loanId : "" : ""}</a></p>
                </div>
                {/* <div className="col-xs-12">
                    <p><b>E-Sign &amp; E-Mandate Link :&nbsp;</b>{allDetail != null ? allDetail.loanId != null ? WEB_URL + "esignEnach/" + allDetail.loanId : "" : ""}</p>
                </div> */}

                {/* <div className="col-xs-12">
                    <p><b>Current Obligation :&nbsp;</b>{allDetail != null && allDetail.loanDetailList != null && allDetail.loanDetailList.length > 0 && allDetail.loanDetailList[0].currentObligation != null ? allDetail.loanDetailList[0].currentObligation : "No Obligation"}</p>
                </div> */}
            </div>
            : null}
        <div className="row">
            <br />
            <div className="col-sm-4 col-xs-12">
                <div className="profile-details-box-new" style={{ minHeight: "500px" }}>
                    <div className="new-basic-head" >Personal Information</div>

                    <div className="">
                        <p className="word-break"><b>Email Id :&nbsp;</b>{allDetail != null ? allDetail.emailId != null ? allDetail.emailId : "" : ""}</p>
                    </div>
                    {userDetail.loanFrom !== 'bazarBussiness' ?

                        <div className="">
                            <p className="word-break">
                                <b>Official Email Id -  :&nbsp;</b>
                                {allDetail != null && allDetail.employmentDetailsPO != null && allDetail.employmentDetailsPO.optionalEmail != null ?
                                    admin != null && admin.rolelist != undefined && admin.rolelist.indexOf(ROLE.COMMUNICATION) >= 0 ?
                                        allDetail.emailVerified == false ?
                                            <a className="pointer red-text" onClick={e => verifyMail(allDetail.employmentDetailsPO.optionalEmail)}>{allDetail.employmentDetailsPO.optionalEmail}</a>
                                            : allDetail.employmentDetailsPO.optionalEmail
                                        : null : ""}
                            </p>
                        </div>
                        : ""}
                    <div className="">
                        <p><b>Mobile Number :&nbsp;</b>{allDetail != null ? allDetail.mobileNumber != null ? allDetail.mobileNumber : "" : ""}</p>
                    </div>
                    {/* {allDetail.nsdlPanStatus != null && allDetail.nsdlPanStatus != ''? */}

                    {/* :""} */}
                    {/* <div className="">
                        
                            <p><b>Pan Number :&nbsp;</b>{allDetail != null ? allDetail.panNumber != null ? allDetail.panNumber : "" : ""}&nbsp;{panVerifiedstate == true?<img src={VerifyImage} width="22px"/>:<img src={NotVerified} width="20px"/>}</p>
                    </div>
                    <div className="">
                        <p className="word-break"><b>Name as on PAN - :&nbsp;</b>{allDetail != null ? allDetail.nameAsOnPan != null ? allDetail.nameAsOnPan : "" : ""}</p>
                    </div>
               {panVerifiedstate == false && admin != null && admin.rolelist != undefined && admin.rolelist.indexOf(ROLE.PAN_VERIFY) >= 0 ?
                <div >
                  <p><input  className="form-control"  placeholder="Name same as on Pancard" onChange={e=>verifyHandler(e)} /><button className="profile-save-btn" onClick={saveVerify}>Verify</button></p>  
                    </div>
                    :""}
                    <div className="">
                        <p className="word-break"><b>Aadhar No. - :</b>&nbsp;{allDetail != null ? allDetail.aadharNumber != null ? allDetail.aadharNumber : "" : ""}</p>
                    </div> */}
                    {userDetail.loanFrom !== 'bazarBussiness' ?
                        <div>
                            <div className="">
                                <p><b>Alternate Number :&nbsp;</b> {allDetail != null ? allDetail.alternativeNo != null ? allDetail.alternativeNo : "" : ""}</p>
                            </div>
                            <div className="">
                                <p><b>App Version :&nbsp;</b> {appVersion ? appVersion : ""}</p>
                            </div>
                            <div className="">
                                <p><b>D.O.B :&nbsp;</b>
                                    {allDetail != null ? allDetail.dateOfBirth != null ? allDetail.dateOfBirth.split("T")[0] : "" : ""}</p>
                            </div>
                            <div className="">
                                <p><b>Gender :&nbsp;</b>{allDetail != null ? allDetail.gender != null ? allDetail.gender : "" : ""}</p>
                            </div>

                            <div className="">
                                {/* {admin != null && admin.rolelist != undefined && admin.rolelist.indexOf(ROLE.AADHAAR_MASKED)>=0 ? <p><b>Is Aadhar Mobile Linked :&nbsp;</b>{allDetail != null && allDetail.isAadharMobileLinked != null ? allDetail.isAadharMobileLinked == 1 ? "Yes" : allDetail.isAadharMobileLinked == 2 ? "No" : "No" : ""}</p>
                                    : <p><b>Is Aadhar Mobile Linked :&nbsp;</b>{allDetail != null && allDetail.isAadharMobileLinked != null ? 'Yes' : ''}</p>} */}
                                {admin.rolelist != undefined ? (
                                    admin.rolelist.indexOf(ROLE.AADHAAR_MASKED) >= 0 ? (
                                        <p><b>Is Aadhar Mobile Linked :&nbsp;</b>{allDetail != null && allDetail.isAadharMobileLinked != null ? allDetail.isAadharMobileLinked == 1 ? "Yes" : allDetail.isAadharMobileLinked == 2 ? "No" : "No" : ""}</p>
                                    ) : <p><b>Is Aadhar Mobile Linked :&nbsp;</b>{allDetail != null && allDetail.isAadharMobileLinked != null ? 'Yes' : ''}</p>) : ""}
                            </div>
                        </div>
                        : ""}

                    {userReference && userReference.length > 0 ?
                        <div>
                            <div className="new-basic-head" style={{ borderTopRightRadius: '0px', borderTopLeftRadius: '0px', marginBottom: '10px', marginTop: '15px' }}>Reference Details</div>
                            <table className="reference-table" >
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Number</th>
                                        <th>Relationship</th>
                                        <td></td>

                                    </tr>
                                </thead>
                                <tbody>
                                    {userReference ? userReference.map((data, i) => {
                                        if (i <= 2) {
                                            return (
                                                <tr>
                                                    <td>{data.name}  </td>
                                                    <td>{data.number}</td>
                                                    <td>{data.relationShip}</td>
                                                    {admin.rolelist.indexOf(ROLE.Mark_RFD) >= 0 ? <td>{data.verified ? <span className="fa fa-check" style={{ color: 'green' }} data-toggle="tooltip" data-placement="bottom" title={data.empId}></span> : <button className='profile-save-btn' style={{ padding: '2px 5px', marginTop: '0px', background: 'rgb(255, 239, 242)' }}><span onClick={() => changeVeriFyStatus(data, i)} style={{ color: 'red', cursor: 'pointer' }}>Verify</span></button>}</td> :
                                                        <td>{data.verified ? <span className="fa fa-check" style={{ color: 'green' }} data-toggle="tooltip" data-placement="bottom" title={data.empId}></span> : <i className='fa fa-times' style={{ color: 'red' }}></i>}</td>}

                                                </tr>
                                            )
                                        }
                                    })
                                        : ""}

                                </tbody>
                            </table>

                        </div>
                        : ""}
                    {vreedhiData != '' && userDetail.loanFrom === "vreedhi" ?
                        <div>
                            <h4 className='new-basic-head' style={{ borderTopRightRadius: '0px', borderTopLeftRadius: '0px', marginBottom: '10px', marginTop: '15px' }}>Vreedhi Recomended Details</h4>
                            <div className="">
                                <p><b>Amount Recomended :&nbsp;</b>
                                    {vreedhiData != null && vreedhiData.amountRecomended != null ? vreedhiData.amountRecomended : ""}</p>
                            </div>
                            <div className="">
                                <p><b>Tenure Recomended :&nbsp;</b>
                                    {vreedhiData != null && vreedhiData.tenureRecomended != null ? vreedhiData.tenureRecomended : ""}</p>
                            </div>
                            <div className="">
                                <p><b>Created On  :&nbsp;</b>
                                    {vreedhiData != null && vreedhiData.createdAt != null ? moment(vreedhiData.createdAt).format("DD MMM YYYY , hh:mm a") : ""}</p>
                            </div>
                            <div className="">
                                <p><b>Family Dependents :&nbsp;</b>
                                    {vreedhiData != null && vreedhiData.familyDependents != null ? vreedhiData.familyDependents : ""}</p>
                            </div>
                            <div className="">
                                <p><b>Id :&nbsp;</b>
                                    {vreedhiData != null && vreedhiData.id != null ? vreedhiData.id : ""}</p>
                            </div>


                        </div>
                        : ""}
                </div>
            </div>
            <div className="col-sm-4 col-xs-12">
                {userDetail.loanFrom !== 'bazarBussiness' ?
                    <div className="profile-details-box-new" style={{ minHeight: "500px" }}>
                        <div className="new-basic-head" >Employment Information</div>

                        {/* <div className="">
                        <p><b>Highest Qualification :&nbsp;</b>{allDetail != null ? allDetail.employmentDetailsPO != null ? allDetail.employmentDetailsPO.highestQualification != null ? allDetail.employmentDetailsPO.highestQualification : "" : "" : ""}</p>
                    </div> */}
                        {/* <div className="">
                        <p><b>Job Type :&nbsp;</b>{allDetail != null ? allDetail.employmentDetailsPO != null ? allDetail.employmentDetailsPO.fullPartTime != null ? allDetail.employmentDetailsPO.fullPartTime : "" : "" : ""}</p>
                    </div> */}
                        <div className="">
                            <p><b>Employer Type :&nbsp;</b>{allDetail != null ? allDetail.employmentDetailsPO != null ? allDetail.employmentDetailsPO.employerType != null ? allDetail.employmentDetailsPO.employerType : "" : "" : ""}</p>
                        </div>
                        <div>
                            {allDetail != null ? allDetail.employmentDetailsPO != null ? allDetail.employmentDetailsPO.employerType == "Salaried" ?
                                <div className="salaried-details">
                                    <div className="sl-slf">
                                        <div className="">
                                            <p><b>Organisation Name :&nbsp;</b> {allDetail != null ? allDetail.employmentDetailsPO != null ? allDetail.employmentDetailsPO.organizationName != null ? allDetail.employmentDetailsPO.organizationName : "" : "" : ""}&nbsp;
                                                {allDetail.employmentDetailsPO.organisationVerified == true ? <img src={VerifyImage} width="22px" /> : <img src={NotVerified} width="20px" />}
                                            </p>
                                        </div>
                                        <div className="">
                                            <p><b>Office Address :&nbsp;</b> {allDetail != null ? allDetail.employmentDetailsPO != null ? allDetail.employmentDetailsPO.officeAddress != null ? allDetail.employmentDetailsPO.officeAddress : "" : "" : ""}</p>
                                        </div>
                                        <div className="">
                                            <p><b>Net Take Home Salary :&nbsp;</b> {allDetail != null ? allDetail.employmentDetailsPO != null ? allDetail.employmentDetailsPO.netTakeHomeSalary != null && allDetail.employmentDetailsPO.netTakeHomeSalary != 0 ? allDetail.employmentDetailsPO.netTakeHomeSalary : "" : "" : ""}</p>
                                        </div>
                                    </div>
                                    {/* <div className="sl-slf">
                                    <div className="">
                                        <p><b>Work Experience (current org) :&nbsp;</b>
                                            {allDetail != null ? allDetail.employmentDetailsPO != null ? allDetail.employmentDetailsPO.workExperienceCurrentOrganization != null ? allDetail.employmentDetailsPO.workExperienceCurrentOrganization : "" : "" : ""}</p>
                                    </div>
                                    <div className="">
                                        <p><b>Is this your first job? :&nbsp;</b>
                                            {allDetail != null ? allDetail.employmentDetailsPO != null ? allDetail.employmentDetailsPO.isThisFirstJob != null ? allDetail.employmentDetailsPO.isThisFirstJob : "" : "" : ""}</p>
                                    </div>
                                    <div className="">
                                        <p><b>Work Experience (previous org) :&nbsp;</b>
                                            {allDetail != null ? allDetail.employmentDetailsPO != null ? allDetail.employmentDetailsPO.workExperiencePreviousOrganization != null ? allDetail.employmentDetailsPO.workExperiencePreviousOrganization : "" : "" : ""}</p>
                                    </div>
                                </div> */}
                                    <div className="sl-slf">
                                        <div className="">
                                            <p><b>Total Experience :&nbsp;</b>
                                                {allDetail != null ? allDetail.employmentDetailsPO != null ? allDetail.employmentDetailsPO.totalExperience != null ? allDetail.employmentDetailsPO.totalExperience : "" : "" : ""}</p>
                                        </div>
                                        <div className="">
                                            <p><b>Designation :&nbsp;</b>
                                                {allDetail != null ? allDetail.employmentDetailsPO != null ? allDetail.employmentDetailsPO.designation != null ? allDetail.employmentDetailsPO.designation : "" : "" : ""}</p>
                                        </div>
                                        <div className="">
                                            <p><b>{'Family Income > 5 Lakh'} :&nbsp;</b>
                                                {allDetail != null ? allDetail.employmentDetailsPO != null ? allDetail.employmentDetailsPO.familyIncomeGreaterThreashold != null ? allDetail.employmentDetailsPO.familyIncomeGreaterThreashold === true ? <img src={VerifyImage} width="22px" /> : <img src={NotVerified} width="20px" /> : "" : "" : ""}</p>
                                        </div>
                                    </div>
                                </div>
                                : "" : "" : ""}
                            {allDetail != null ? allDetail.employmentDetailsPO != null ? allDetail.employmentDetailsPO.employerType == "Self Employed" && userDetail.loanFrom !== "vreedhi" ?
                                <div className="self-emp-details">
                                    <div className="sl-slf">
                                        <div className="">
                                            <p><b>Self-Employee Type :&nbsp;</b>
                                                {allDetail != null ? allDetail.employmentDetailsPO != null ? allDetail.employmentDetailsPO.selfEmployeeType != null ? allDetail.employmentDetailsPO.selfEmployeeType : "" : "" : ""}</p>
                                        </div>
                                        <div className="">
                                            <p><b>Industry Type :&nbsp;</b>
                                                {allDetail != null ? allDetail.employmentDetailsPO != null ? allDetail.employmentDetailsPO.businessIndustryType != null ? allDetail.employmentDetailsPO.businessIndustryType : "" : "" : ""}</p>
                                        </div>
                                        <div className="">
                                            <p><b>Employment role :&nbsp;</b>-
                                                {allDetail != null ? allDetail.employmentDetailsPO != null ? allDetail.employmentDetailsPO.employmentRole != null ? allDetail.employmentDetailsPO.employmentRole : "" : "" : ""}</p>
                                        </div>
                                    </div>
                                    <div className="sl-slf">
                                        <div className="">
                                            <p><b>Monthly Income :&nbsp;</b>
                                                {allDetail != null ? allDetail.employmentDetailsPO != null ? allDetail.employmentDetailsPO.monthlyIncome != null ? allDetail.employmentDetailsPO.monthlyIncome : "" : "" : ""}</p>
                                        </div>
                                        <div className="">
                                            <p><b>Business Start Date :&nbsp;</b>
                                                {allDetail != null ? allDetail.employmentDetailsPO != null ? allDetail.employmentDetailsPO.businessStartDate != null ? allDetail.employmentDetailsPO.businessStartDate : "" : "" : ""}</p>
                                        </div>
                                    </div>
                                </div>
                                : "" : "" : ""}
                            {allDetail != null && vreedhiData != '' && userDetail.loanFrom === "vreedhi" && allDetail.employmentDetailsPO != null ?
                                <div className="self-emp-details">
                                    {/* <div className="sl-slf"> */}
                                    {/* <h4 className='new-basic-head' style={{ borderTopRightRadius: '0px', borderTopLeftRadius: '0px', marginBottom: '10px', marginTop: '15px' }}>Vreedhi Details</h4> */}

                                    <div className="">
                                        <p><b>Business Name :&nbsp;</b>
                                            {vreedhiData != null && vreedhiData.name != null ? vreedhiData.name : ""}</p>
                                    </div>
                                    <div className="">
                                        <p><b>Business Address :&nbsp;</b>
                                            {vreedhiData != null && vreedhiData.address != null ? vreedhiData.address : ""}</p>
                                    </div>
                                    <div className="">
                                        <p><b>Business Category :&nbsp;</b>-
                                            {vreedhiData != null && vreedhiData.category != null ? vreedhiData.category : ""}</p>
                                    </div>
                                    {/* </div> */}
                                    <div className="">
                                        <p><b>Business Segment :&nbsp;</b>
                                            {vreedhiData != null && vreedhiData.segment != null ? vreedhiData.segment : ""}</p>
                                    </div>
                                    <div className="">
                                        <p><b>Monthly Turnover :&nbsp;</b>
                                            {vreedhiData != null && vreedhiData.monthlyTurnover != null ? vreedhiData.monthlyTurnover : ""}</p>
                                    </div>
                                    <div className="">
                                        <p><b>Net Business Income :&nbsp;</b>
                                            {vreedhiData != null && vreedhiData.netBussinessIncome != null ? vreedhiData.netBussinessIncome : ""}</p>
                                    </div>


                                    <h4 className='new-basic-head' style={{ borderTopRightRadius: '0px', borderTopLeftRadius: '0px', marginBottom: '10px', marginTop: '15px' }}>Vreedhi Contact Details</h4>
                                    <div className="">
                                        <p><b>CL Name :&nbsp;</b>
                                            {vreedhiData != null && vreedhiData.clName != null ? vreedhiData.clName : ""}</p>
                                    </div>
                                    <div className="">
                                        <p><b>CL Mail Id  :&nbsp;</b>
                                            {vreedhiData != null && vreedhiData.clMailId != null ? vreedhiData.clMailId : ""}</p>
                                    </div>
                                    <div className="">
                                        <p><b>CL Mobile No. :&nbsp;</b>
                                            {vreedhiData != null && vreedhiData.clMobile != null ? vreedhiData.clMobile : ""}</p>
                                    </div>
                                    <div className="">
                                        <p><b>RCM Name :&nbsp;</b>
                                            {vreedhiData != null && vreedhiData.rcmName != null ? vreedhiData.rcmName : ""}</p>
                                    </div>
                                    <div className="">
                                        <p><b>RCM Mail Id :&nbsp;</b>
                                            {vreedhiData != null && vreedhiData.rcmMailId != null ? vreedhiData.rcmMailId : ""}</p>
                                    </div>
                                    <div className="" style={{ paddingBottom: '10px' }}>
                                        <p><b>RCM Mobile No. :&nbsp;</b>
                                            {vreedhiData != null && vreedhiData.rcmMobile != null ? vreedhiData.rcmMobile : ""}</p>
                                    </div>
                                </div>
                                : ""}
                        </div>
                        <h4 className='new-basic-head' style={{ borderTopRightRadius: '0px', borderTopLeftRadius: '0px', marginBottom: '10px', marginTop: '15px' }}>KARZA Details</h4>

                        <div className="">

                            <p><b>Pan Verified :&nbsp;</b><p style={{ float: 'right' }}><b>{karzaData && karzaData.status !== undefined && karzaData.status !== null ? karzaData.status === 'Active' ? <span style={{ color: 'green' }}>{karzaData.status}</span> : <span style={{ color: 'red' }}>{karzaData.status}</span> : "No Data Found"} </b>&nbsp; {karzaData !== '' && karzaData !== null && karzaData !== undefined ? <button className='i-button' onClick={(e) => karzaPopup(e, 'pan')}><i className="fa fa-info" /></button> : ""} </p></p>

                            {/* <p><b>Pan Verified :&nbsp;</b><p style={{ float: 'right' }}>{paninfo ? <img src={VerifyImage} width="22px" /> : <img src={NotVerified} width="22px" />} &nbsp; <button className='i-button' onClick={(e) => karzaPopup(e, 'pan')}><i className="fa fa-info" /></button> </p></p> */}
                        </div>
                        {/* {allDetail.isAadharMobileLinked == 1? */}
                        {/* <div className="" style={{ paddingBottom: '10px' }}> */}
                        <div style={{ marginTop: '18px' }}>
                            {/* <p><b>AADHAR Verified:&nbsp;</b><p style={{ float: 'right' }}><b>{aadharDataFromKarza !== '' && aadharDataFromKarza.status !== undefined && aadharDataFromKarza.status !== null ? aadharDataFromKarza.status === true ? <span style={{ color: 'green' }}>True</span> : <span style={{ color: 'red' }}>False</span> : "No Data Found"}</b> &nbsp;{aadharInfo === true ? <button className='i-button' onClick={(e) => karzaPopup(e, 'aadhar')}><span className="fa fa-info" /></button> : ""} </p></p> */}

                            <p><b>AADHAR Verified:&nbsp;</b><p style={{ float: 'right' }}>{aadharInfo ? <img src={VerifyImage} width="22px" /> : <img src={NotVerified} width="22px" />} &nbsp;{aadharInfo ? <button className='i-button' onClick={(e) => karzaPopup(e, 'aadhar')}><span className="fa fa-info" /></button> : ""} </p></p>
                        </div>
                        {/* </div> */}
                        {/* :""} */}



                    </div>
                    : ""}
                {userDetail.loanFrom === 'bazarBussiness' ?
                    <div className="profile-details-box-new" style={{ minHeight: "500px" }}>
                        <div className="new-basic-head" >Account Details</div>
                        <div className="">
                            <p><b>Bank Name :&nbsp;</b>
                                {bankData != null && bankData.bankName != null ? bankData.bankName : ""}</p>
                        </div>
                        <div className="">
                            <p><b>Account Number :&nbsp;</b>
                                {bankData != null && bankData.accountNumber != null ? bankData.accountNumber : ""}</p>
                        </div>
                        <div className="">
                            <p><b>Name As on Bank Account :&nbsp;</b>
                                {bankData != null && bankData.nameAsBankAccount != null ? bankData.nameAsBankAccount : ""}</p>
                        </div>
                        <div className="">
                            <p><b>IFSC Code :&nbsp;</b>
                                {bankData != null && bankData.ifscCode != null ? bankData.ifscCode : ""}</p>
                        </div>

                    </div>

                    : ""}
            </div>
            <div className="col-sm-4 col-xs-12">
                <div className="profile-details-box-new" style={{ minHeight: "500px" }}>
                    <div className="new-basic-head" >Residential Information</div>

                    <div className="orange-text">
                        <p>Current Address</p>
                    </div>
                    <div className="">
                        <p><b>Address :&nbsp;</b>
                            {allDetail != null ? allDetail.addressDetailsPOList != null ?
                                <span>
                                    {allDetail.addressDetailsPOList.currentAddress != null ? allDetail.addressDetailsPOList.currentAddress + ", " : ""}
                                    {allDetail.addressDetailsPOList.currentCity != null ? allDetail.addressDetailsPOList.currentCity + ", " : ""}
                                    {allDetail.addressDetailsPOList.currentState != null ? allDetail.addressDetailsPOList.currentState + ", - " : ""}
                                    {allDetail.addressDetailsPOList.currentPincode != 0 && allDetail.addressDetailsPOList.currentPincode != null ? allDetail.addressDetailsPOList.currentPincode : ""}
                                </span>
                                : "" : ""}

                        </p>
                    </div>
                    {/* <div className="">
                        <p><b>Residence Type :&nbsp;</b>{allDetail != null ? allDetail.addressDetailsPOList != null ? allDetail.addressDetailsPOList.currentResidenceType != null ? allDetail.addressDetailsPOList.currentResidenceType : "" : "" : ""}</p>
                    </div>
                    {allDetail != null ? allDetail.addressDetailsPOList != null ? allDetail.addressDetailsPOList.currentResidenceType != "Own" ?
                        <div className="">
                            <p><b>Stay Duration :&nbsp;</b>{allDetail != null ? allDetail.addressDetailsPOList != null ? allDetail.addressDetailsPOList.currentResidenceValue != null ? allDetail.addressDetailsPOList.currentResidenceValue : "" : "" : ""}</p>
                        </div>
                        : "" : "" : ""} */}
                    <div className="orange-text">
                        <p>Permanent Address</p>
                    </div>
                    <div className="">
                        <p><b>Address :&nbsp;</b>
                            {allDetail != null ? allDetail.addressDetailsPOList != null ?
                                <span>
                                    {allDetail.addressDetailsPOList.permanentAddress != null ? allDetail.addressDetailsPOList.permanentAddress + ", " : ""}
                                    {allDetail.addressDetailsPOList.permanentCity != null ? allDetail.addressDetailsPOList.permanentCity + ", " : ""}
                                    {allDetail.addressDetailsPOList.permanentState != null ? allDetail.addressDetailsPOList.permanentState + ", - " : ""}
                                    {allDetail.addressDetailsPOList.permanentPincode != 0 && allDetail.addressDetailsPOList.permanentPincode != null ? allDetail.addressDetailsPOList.permanentPincode : ""}
                                </span>
                                : "" : ""}
                        </p>
                    </div>
                    {/* <div className="">
                        <p><b>Residence Type :&nbsp;</b>{allDetail != null ? allDetail.addressDetailsPOList != null ? allDetail.addressDetailsPOList.permanentResidenceType : "" : ""}</p>
                    </div> */}
                    {/* {allDetail != null ? allDetail.addressDetailsPOList != null ? allDetail.addressDetailsPOList.permanentResidenceType != "Own" ?
                        <div className="">
                            <p ><b>Stay Duration :&nbsp;</b>{allDetail != null ? allDetail.addressDetailsPOList != null ? allDetail.addressDetailsPOList.permanentResidenceValue != null ? allDetail.addressDetailsPOList.permanentResidenceValue : "" : "" : ""}</p>
                        </div>
                        : "" : "" : ""} */}
                    <div className="">
                        <p><b>Aadhar Address Same As Current Address :&nbsp;</b>{allDetail != null && allDetail.aadharAndCurrentAddress != null ? allDetail.aadharAndCurrentAddress == 1 ? "Yes" : allDetail.aadharAndCurrentAddress == 2 ? "No" : "No" : ""}</p>
                    </div>
                    <div >
                        <h4 className='new-basic-head' style={{ borderTopRightRadius: '0px', borderTopLeftRadius: '0px', marginBottom: '10px', marginTop: '15px' }}>Identity Information</h4>
                        <div className="">
                            <p><b>Pan Number :&nbsp;</b>{allDetail != null ? allDetail.panNumber != null ? allDetail.panNumber : "" : ""}
                                {/* &nbsp;{panVerifiedstate == true ? <img src={VerifyImage} width="22px" /> : <img src={NotVerified} width="20px" />} */}
                            </p>
                        </div>
                        <div className="">
                            <p className="word-break"><b>Name as on PAN - :&nbsp;</b>{allDetail != null ? allDetail.nameAsOnPan != null ? allDetail.nameAsOnPan : "" : ""}</p>
                        </div>
                        {/* {panVerifiedstate == false && admin != null && admin.rolelist != undefined && admin.rolelist.indexOf(ROLE.PAN_VERIFY) >= 0 ?
                            <div >
                                <p style={{ display: 'flex' }}><input className="form-control" placeholder="Name same as on Pancard" onChange={e => verifyHandler(e)} /><button style={{ marginTop: '0', marginBottom: '0' }} className="profile-save-btn" onClick={saveVerify}>Verify</button></p>
                            </div>
                            : ""} */}
                        <div className="">
                            <p className="word-break"><b>Pan Status By Nsdl :&nbsp;</b>{allDetail.nsdlPanStatus != '' && allDetail.nsdlPanStatus != null ? allDetail.nsdlPanStatus : "No Data Found"}</p>
                        </div>
                        <div className="">

                            {admin.rolelist != undefined ? (
                                admin.rolelist.indexOf(ROLE.AADHAAR_MASKED) >= 0 ? (
                                    <p><b>Aadhaar Number :&nbsp;</b>{allDetail != null && allDetail.aadharNumber != null ? allDetail.aadharNumber : ""}</p>
                                ) : <p><b>Aadhaar Number :&nbsp;</b>{maskAadhar != '' && maskAadhar !== undefined ? maskAadhar : ''}</p>) : ""}

                        </div>
                    </div>
                </div>

            </div>
        </div>

    </div>
)

export default userBasic;