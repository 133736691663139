import React, { useState } from "react";
import DepartMentList from "./Department/mainDepartment";
import TargetList from "./Targetssalary/mainTargets";

import EmpList from "./Employee/mainEmp";
const MainEmp = ({ admin }) => {
    const [viewType, setviewType] = useState('emp')
    const [editData, seteditData] = useState('')

    const editHandler = (data) => {
        console.log(data)
        if (data.id) {
            seteditData(data)
            setviewType('add')
        }



    }
    const ChangeScreen = (type) => {
        seteditData('')
        setviewType(type)
    }
    return (
        <div className="container-fluid">
            {/* <div style={{ display: 'flex', textAlign: 'center' }}>
                <div onClick={() => ChangeScreen('list')} style={{ color: viewType === 'list' ? '#fff' : '#000', background: viewType === 'list' ? '#337ab7' : '#f4f3f1', padding: '10px', borderRadius: '5px', width: '40px', height: '40px', cursor: 'pointer' }}> <i className="fa fa-list" ></i></div>&nbsp;&nbsp;

            </div> */}
            <div className="row fixed-top" style={{ marginTop: '80px', display: 'flex', width: '100%' }}>

                <div className="" onClick={() => setviewType('emp')} style={{ borderBottom: viewType === 'emp' ? '2px solid #267DFF' : '', background: viewType === 'emp' ? '#267DFF' : '#DBE9FF', padding: viewType === 'emp' ? '13px' : '12.2px', textAlign: 'center', color: viewType === 'emp' ? '#fff' : '#000', cursor: 'pointer', width: '33.33%' }}>
                    Employees

                </div>
                <div className="" onClick={() => setviewType('department')} style={{ borderBottom: viewType === 'department' ? '2px solid #267DFF' : '', background: viewType === 'department' ? '#267DFF' : '#DBE9FF', padding: viewType === 'department' ? '13px' : '12.2px', textAlign: 'center', color: viewType === 'department' ? '#fff' : '#000', cursor: 'pointer', width: '33.33%' }}>
                    Departments

                </div>
                <div className="" onClick={() => setviewType('target')} style={{ borderBottom: viewType === 'target' ? '2px solid #267DFF' : '', background: viewType === 'target' ? '#267DFF' : '#DBE9FF', padding: viewType === 'target' ? '13px' : '12.2px', textAlign: 'center', color: viewType === 'target' ? '#fff' : '#000', cursor: 'pointer', width: '33.33%' }}>
                    Targets

                </div>
            </div>
            <div style={{ marginTop: '40px' }}>
                {viewType === 'emp' ?
                    <EmpList showButton={false} admin={admin} editHandler={editHandler} />
                    : ""}
                {viewType === 'department' ?
                    <DepartMentList showButton={false} admin={admin} editData={editData} />
                    : ""}
                {viewType === 'target' ?
                    <TargetList showButton={false} admin={admin} editData={editData} />
                    : ""}
            </div>

        </div>
    )
}
export default MainEmp